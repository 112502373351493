import React from 'react';

import theme from '../../theme';

const Footer: React.FC = () => (
  <div
    style={{
      marginTop: '5rem',
      marginBottom: '2rem',
      textAlign: 'center',
      color: theme.colors.textGreen,
    }}
  >
    <br />
    <em>
      {' '}
      &copy; {new Date().getFullYear()}{' '}
      <a
        href='https://www.lantmannenagro.fi/'
        target='_blank'
        rel='noopener noreferrer'
      >
        {' '}
        Lantmännen Agro{' '}
      </a>{' '}
    </em>
  </div>
);

export default Footer;
