import React from 'react';
import { Segment } from 'semantic-ui-react';

const Disclaimer: React.FC = () => (
  <Segment secondary textAlign='left' compact size='small'>
    Täysmaidon normaali kuiva-ainepitoisuus on noin 13%. Vahvemmaksi tehty juoma
    antaa vasikalle enemmän energiaa. Suositeltavaa on nostaa juoman kuiva-aine
    korkeintaan 15%:iin.
  </Segment>
);

export default Disclaimer;
