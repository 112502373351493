import React from 'react';
import theme from '../../theme';

interface Props {
  result: number;
  litresTotal: number;
}

const ResultWater: React.FC<Props> = ({ result, litresTotal }) => {
  return (
    <div translate={"no"}>
      LISÄÄ{' '}
      <span
        id='add-water'
        style={{
          color: result > 0 ? theme.colors.textGreen : 'red',
          fontSize: '125%',
        }}
      >
        <strong>
          {result < 0 || litresTotal < result ? 0 : result.toFixed()}
        </strong>
      </span>{' '}
      LITRAA VETTÄ
    </div>
  );
};
export default ResultWater;
