import React from 'react';
import { Form } from 'semantic-ui-react';
import { getBrixValueOptions, brixWantedOptions } from '../../constants';
import theme from '../../theme';

interface Props {
  brixStart: number;
  litresStart: number;
  brixWanted: number;
  litresTotal: number;
  onBrixStartChange: (value: number) => void;
  setLitresStart: (value: number) => void;
  onBrixWantedValueChange: (value: number) => void;
  onTotalChange: (value: number) => void;
}

const CalculatorForm: React.FC<Props> = ({
  brixStart,
  litresStart,
  brixWanted,
  litresTotal,
  onBrixStartChange,
  setLitresStart,
  onBrixWantedValueChange,
  onTotalChange,
}) => (
  <Form>
    <Form.Field>
      <label style={{ color: theme.colors.bgGreen }}>
        Maidon Brix-arvo (hyppää yli mikäli maitoa ei käytetä)
      </label>
      <select
        id='brix-start'
        value={brixStart}
        onChange={(e) => onBrixStartChange(Number(e.target.value))}
      >
        {getBrixValueOptions().map((option) => (
          <option key={option.key} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </Form.Field>
    <Form.Field>
      <label style={{ color: theme.colors.bgGreen }}>
        Maidon määrä litroissa (syötä 0 mikäli maitoa ei käytetä)
      </label>
      <input
        value={Number(litresStart).toString()}
        type='number'
        id='litres-start'
        onChange={(e) => setLitresStart(Number(e.target.value))}
        placeholder='Maidon määrä litroissa'
      />
    </Form.Field>
    <Form.Field>
      <label style={{ color: theme.colors.bgGreen }}>
        Valmiin juoman kuiva-ainetavoiteprosentti
      </label>
      <select
        id='brix-wanted'
        value={brixWanted}
        onChange={(e) => onBrixWantedValueChange(Number(e.target.value))}
      >
        {brixWantedOptions.map((option) => (
          <option key={option.key} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </Form.Field>
    <Form.Field>
      <label style={{ color: theme.colors.bgGreen }}>
        Juoman kokonaismäärä litroissa
      </label>
      <input
        id='litres-total'
        value={Number(litresTotal).toString()}
        type='number'
        onChange={(e) => onTotalChange(Number(e.target.value))}
        placeholder='Kokonaismäärä litraa'
      />
    </Form.Field>
  </Form>
);

export default CalculatorForm;
