const theme = {
  colors: {
    main: '#3aaa35',
    textGreen: '#009636',
    textDark: '#575756',
    bgWhite: '#ffffff',
    bgLight: '#F7F2EC',
    bgGreen: '#006f30',
  },
};

export default theme;
