import React, { useState, useEffect } from 'react';

import theme from '../../theme';

import CalculatorForm from './CalculatorForm';
import ResultPowder from './ResultPowder';
import ResultWater from './ResultWater';
import MinimumLitres from './MinimumLitres';
import Disclaimer from './Disclaimer';

const Calculator: React.FC = () => {
  // calculator follows the example of http://calvex.dk/it/en/index.htm
  // should the value of litres total update
  const [litresTotalUpdate, setLitresTotalUpdate] = useState(false);

  // Brix value %
  const [brixStart, setBrixStart] = useState<number>(10);

  // Initial amount, litres
  const [litresStart, setLitresStart] = useState<number>(100);

  // Goal, dry matter
  const [brixWanted, setBrixWanted] = useState<number>(12);

  // Amount to add milk powder, kg
  const result = (((brixWanted - brixStart) * 11) / 1000) * litresStart;

  // calculate final amount, litres
  const litresTotalCalc = litresStart * 1 + 0.7 * result;

  // Final amount, litres
  const [litresTotal, setLitresTotal] = useState<number>(
    Math.round(litresTotalCalc)
  );

  const [litresMin, setLitresMin] = useState<number>(
    Math.round(litresTotalCalc)
  );

  // amount of milk powder to add, kilograms
  const [addMilkPowder, setAddMilkPowder] = useState<number>(result);

  // calculate initial water to add result amount
  const water = litresTotal - litresStart - result * 0.7;

  // set water to add result amount
  const [addWater, setAddWater] = useState(water);

  // calculate results based on litres total change
  useEffect(() => {
    if (litresTotalUpdate) {
      let result = (((brixWanted - brixStart) * 11) / 1000) * litresStart;
      let water = litresTotal - litresStart - result * 0.7;

      const dryStart = brixStart * 1 + 2;
      const dryWanted = brixWanted * 1 + 2;

      // step 1 - x kg dry matter
      const stepOne = (litresStart * dryStart) / 100;

      // step 2 - x kg dry matter
      const stepTwo = (litresTotal * dryWanted) / 100;

      // step 3 - x kg dry matter
      const stepThree = stepTwo - stepOne;

      // step 4 - x kg powder
      result = stepThree / 0.97;

      // step 5 - x liters
      const stepFive = result * 0.7;

      // step 6 - x liters
      water = litresTotal - litresStart - stepFive;

      if (result < 0) {
        result = 0;
      }

      setAddMilkPowder(result);
      setAddWater(water);
    }
  }, [litresTotal]);

  useEffect(() => {
    setLitresTotalUpdate(false);
    const result = (((brixWanted - brixStart) * 11) / 1000) * litresStart;
    if (result < 0) {
      const dryWanted = brixWanted * 1 + 2;
      const dryStart = brixStart * 1 + 2;
      const tsStart = (litresStart * dryStart) / 100;

      let litresTotalCalc = (tsStart / dryWanted) * 100; // calculate how many liters you get in total
      let water = litresTotalCalc - litresStart; // the amount of water to be added

      litresTotalCalc = Number(litresTotalCalc.toFixed(0));
      water = Number(water.toFixed(0));

      setAddMilkPowder(0);
      setAddWater(water);
      setLitresTotal(litresTotalCalc);
      setLitresMin(litresTotalCalc);
    } else {
      const litresTotalCalc = litresStart * 1 + 0.7 * result;

      setLitresMin(Math.round(litresTotalCalc));
      setLitresTotal(Math.round(litresTotalCalc));
      setAddMilkPowder(result);
      setAddWater(0);
    }
  }, [brixStart, brixWanted, litresStart]);

  const onBrixStartChange = (value: number) => {
    setBrixStart(Number(value));
  };

  const onBrixWantedValueChange = (value: number) => {
    setBrixWanted(Number(value));
  };

  const onTotalChange = (value: number) => {
    setLitresTotalUpdate(true);
    setLitresTotal(value);
  };

  return (
    <React.Fragment>
      <h1 style={{ color: theme.colors.main }}>
        Laskuri juomarehulle tai maidon ja juomarehun seokselle
      </h1>
      <CalculatorForm
        brixStart={brixStart}
        litresStart={litresStart}
        brixWanted={brixWanted}
        litresTotal={litresTotal}
        onBrixStartChange={onBrixStartChange}
        setLitresStart={setLitresStart}
        onBrixWantedValueChange={onBrixWantedValueChange}
        onTotalChange={onTotalChange}
      />
      <MinimumLitres litresMin={litresMin} />
      <div
        style={{
          margin: '1rem 0.5rem',
          padding: '1rem 1rem',
          backgroundColor: theme.colors.bgLight,
        }}
      >
        <ResultPowder result={addMilkPowder} />
        <ResultWater litresTotal={litresTotal} result={addWater} />
      </div>
      <Disclaimer />
    </React.Fragment>
  );
};

export default Calculator;
