interface BrixValueOptions {
  key: number;
  text: string;
  value: number;
}

export const getBrixValueOptions = (): BrixValueOptions[] => {
  const brixValues = [];
  let initialValue = 4;

  while (initialValue <= 15.4) {
    brixValues.push({
      key: Number(initialValue.toFixed(1)),
      text: initialValue.toFixed(1).endsWith('.0')
        ? `${initialValue.toFixed()}%`
        : `${initialValue.toFixed(1)}%`,
      value: Number(initialValue.toFixed(1)),
    });

    initialValue = initialValue + 0.2;
  }

  return brixValues;
};

export const brixWantedOptions = [
  {
    key: '13%',
    text: '13%',
    value: 11,
  },
  {
    key: '14%',
    text: '14%',
    value: 12,
  },
  {
    key: '15%',
    text: '15%',
    value: 13,
  },
  {
    key: '16%',
    text: '16%',
    value: 14,
  },
  {
    key: '17%',
    text: '17%',
    value: 15,
  },
];
