import React, { useState, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import MenuBar from './components/layout/MenuBar';
import Footer from './components/layout/Footer';
import Notification from './components/layout/Notification';
import Calculator from './components/calculator';

const App: React.FC = () => {
  const [notification, setNotification] = useState({
    status: false,
    message: '',
    type: '',
  });

  return (
    <Container>
      <MenuBar />
      <Notification notification={notification} />
      <Calculator />
      <Footer />
    </Container>
  );
};

export default App;
