import React from 'react';
import theme from '../../theme';

interface Props {
  result: number;
}

const ResultPowder: React.FC<Props> = ({ result }) => {
  return (
    <div translate={"no"}>
      LISÄÄ{' '}
      <span
        style={{
          color: result > 0 ? theme.colors.textGreen : 'red',
          fontSize: '125%',
        }}
        id='add-milk-powder'
      >
        <strong>{result < 1 ? result.toFixed(2) : result.toFixed(1)}</strong>
      </span>{' '}
      KG JUOMAJAUHETTA{' '}
    </div>
  );
};
export default ResultPowder;
