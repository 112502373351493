import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import App from './App';
import {createRoot} from "react-dom/client";

const rootElement = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
