import React from 'react';

import { Menu, Image } from 'semantic-ui-react';
import logo from '../../img/logo.png';

const MenuBar: React.FC = () => (
  <Menu color='orange' pointing secondary>
    <Menu.Item link>
      <Image alt='logo' src={logo} size='medium' />
    </Menu.Item>
  </Menu>
);

export default MenuBar;
