import React from 'react';

interface Props {
  litresMin: number;
}

const MinimumLitres: React.FC<Props> = ({ litresMin }) => {
  return litresMin > 0 ? (
    <div style={{ margin: '3px' }}>
      <i>Juoman kokonaismäärän oltava vähintään</i> <strong>{litresMin}</strong>{' '}
      <i>litraa.</i>
    </div>
  ) : null;
};

export default MinimumLitres;
